import React, { useEffect } from 'react'
import Header from '../components/Header'


const Pricing = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <React.Fragment>
      <Header/>
      <div style={{marginTop:100}}>Pricing</div>
    </React.Fragment>
  )
}

export default Pricing