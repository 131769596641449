import React, { useEffect } from "react";
import { GoogleLogin } from "@react-oauth/google";

const Signup = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          console.log(credentialResponse);
        }}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </React.Fragment>
  );
};

export default Signup;
