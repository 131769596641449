import React, { useEffect } from "react";
import Header from "../components/Header";
import digitalsvg from "../images/digital-svg-min.jpg";
import styles from "../css/Home.module.css";
import {
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// import digitalproduct from "../images/digital product.jpg";
// import payment from "../images/payment.jpg";
// import fbad from "../images/facebook ad.png";
// import gad from "../images/google ad.png";
// import landing from "../images/landing.png";
// import web from "../images/web.png";
// import app from "../images/app.png";
// import consult from "../images/consult.jpg";
import webdev from "../images/webdev.jpg";
import digitalmarketing from "../images/digitalmarketing.jpg";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title="Power Up Business | ByteHub"
  }, []);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <React.Fragment>
      <Header />
      {isMatch ? (
        <>
          <div className={styles.bannerContainerMobile}>
            <div className={styles.bannerTextContainerMobile}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: 45,
                  marginTop: 5,
                  marginRight: "auto",
                  color: "#2F1C6A",
                }}
              >
                Your Digital
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: 45,
                  marginRight: "auto",
                  color: "#2F1C6A",
                }}
              >
                Success Starts Here
              </Typography>
              <p style={{ textAlign: "left" }}>
                Empower your digital presence with our tailored solutions. We
                specialize in delivering cutting-edge digital services to
                elevate your brand, enhance user experiences, and drive
                measurable results. From web development and design to digital
                marketing strategies
              </p>
              <div className={styles.btnContainer}>
                <button
                  className={styles.outlineButtonMobile}
                  onClick={() => navigate("/about-us")}
                >
                  Learn More
                </button>
                <button
                  className={styles.containButtonMobile}
                  onClick={() => navigate("/contact-us")}
                >
                  Enquiry Now
                </button>
              </div>
            </div>
            <div>
              <img
                src={digitalsvg}
                alt="banner"
                style={{ height: 300, width: "100%", marginTop: 10 }}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.bannerContainer}>
            <div className={styles.leftBannerContainer}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontFamily: "arial",
                  fontSize: 45,
                  marginTop: 5,
                  marginRight: "auto",
                  color: "#2F1C6A",
                }}
              >
                Your Digital
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontFamily: "arial",
                  fontSize: 45,
                  marginRight: "auto",
                  color: "#2F1C6A",
                }}
              >
                Success Starts Here
              </Typography>
              <p style={{ textAlign: "left" }}>
                Empower your digital presence with our tailored solutions. We
                specialize in delivering cutting-edge digital services to
                elevate your brand, enhance user experiences, and drive
                measurable results. From web development and design to digital
                marketing strategies
              </p>
              <div className={styles.btnContainer}>
                <button
                  className={styles.outlineButton}
                  onClick={() => navigate("/about-us")}
                >
                  Learn More
                </button>
                <button
                  className={styles.containButton}
                  onClick={() => navigate("/contact-us")}
                >
                  Enquiry Now
                </button>
              </div>
            </div>
            <div>
              <img
                src={digitalsvg}
                alt="banner"
                style={{ height: 550, width: 700, marginRight: 10 }}
              />
            </div>
          </div>
        </>
      )}

      <h1 style={{ textAlign: "center", fontWeight: "bold", margin: 50 }}>
        <u style={{ textDecorationColor: "blue", textDecorationThickness: 5 }}>
          Our Services
        </u>
      </h1>

      {isMatch ? (
        <>
          <div className={styles.detailContainerMobile}>
            <div className={styles.leftContainer1Mobile}>
              <h1 style={{ textAlign: "center", marginBottom: 50 }}>
                We Offer Business Automation
              </h1>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "red" }}
              >
                <p style={{ paddingLeft: 6 }}>Appointment Scheduling</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "blue" }}
              >
                <p style={{ paddingLeft: 6 }}>Inventory Management</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "green" }}
              >
                <p style={{ paddingLeft: 6 }}>Invoices</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "darkcyan" }}
              >
                <p style={{ paddingLeft: 6 }}>Customer Book</p>
              </div>
              
            </div>
            <div className={styles.detailImageContainerMobile}>
              <img
                src={webdev}
                alt="web development"
                style={{ height: 300, width: "100%", marginTop: 20, }}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.detailContainer}>
            <div className={styles.leftContainer1}>
            <h1 style={{ textAlign: "center", marginBottom: 50 }}>
                We Offer Business Automation
              </h1>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "red" }}
              >
                <p style={{ paddingLeft: 6 }}>Appointment Scheduling</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "blue" }}
              >
                <p style={{ paddingLeft: 6 }}>Inventory Management</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "green" }}
              >
                <p style={{ paddingLeft: 6 }}>Invoices</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "darkcyan" }}
              >
                <p style={{ paddingLeft: 6 }}>Customer Book</p>
              </div>
            </div>
            <div className={styles.rightContainer1}>
              <img
                src={webdev}
                alt="web development"
                style={{ height: 550, width: 700, marginRight: 10,}}
              />
            </div>
          </div>
        </>
      )}
      {isMatch ? (
        <>
          <div className={styles.detailContainerMobile}>
            <div className={styles.leftContainer1Mobile}>
              <h1 style={{ textAlign: "center", marginBottom: 50 }}>
                We Offer Digital Marketing
              </h1>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "red" }}
              >
                <p style={{ paddingLeft: 6 }}>Google Ads</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "blue" }}
              >
                <p style={{ paddingLeft: 6 }}>Facebook Ads</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "green" }}
              >
                <p style={{ paddingLeft: 6 }}>E-Mail Marketing</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "darkcyan" }}
              >
                <p style={{ paddingLeft: 6 }}>Sales Funnels</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "orange" }}
              >
                <p style={{ paddingLeft: 6 }}>Bulk Messaging</p>
              </div>
            </div>
            <div className={styles.detailImageContainerMobile}>
              <img
                src={digitalmarketing}
                alt="web development"
                style={{ height: 300, width: '100%', marginTop: 10 }}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.detailContainer}>
            <div className={styles.leftContainer2}>
              <img
                src={digitalmarketing}
                alt="digital marketing"
                style={{ height: 500, width: 650, marginRight: 50 }}
              />
            </div>
            <div className={styles.rightContainer2}>
              <h1
                style={{
                  textAlign: "center",
                  marginBottom: 50,
                  marginRight: "auto",
                }}
              >
                We Offer Digital Marketing
              </h1>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "red" }}
              >
                <p style={{ paddingLeft: 6 }}>Google Ads</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "blue" }}
              >
                <p style={{ paddingLeft: 6 }}>Facebook Ads</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "green" }}
              >
                <p style={{ paddingLeft: 6 }}>E-Mail Marketing</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "darkcyan" }}
              >
                <p style={{ paddingLeft: 6 }}>Sales Funnels</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "orange" }}
              >
                <p style={{ paddingLeft: 6 }}>Bulk Messaging</p>
              </div>
            </div>
          </div>
        </>
      )}
            {isMatch ? (
        <>
          <div className={styles.detailContainerMobile}>
            <div className={styles.leftContainer1Mobile}>
              <h1 style={{ textAlign: "center", marginBottom: 50 }}>
                We Offer Data Storage
              </h1>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "red" }}
              >
                <p style={{ paddingLeft: 6 }}>Visitor Register</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "blue" }}
              >
                <p style={{ paddingLeft: 6 }}>Registration Form Service</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "green" }}
              >
                <p style={{ paddingLeft: 6 }}>Form Management</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "darkcyan" }}
              >
                <p style={{ paddingLeft: 6 }}>Custom Field</p>
              </div>
              
            </div>
            <div className={styles.detailImageContainerMobile}>
              <img
                src={webdev}
                alt="web development"
                style={{ height: 300, width: "100%", marginTop: 20, }}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.detailContainer}>
            <div className={styles.leftContainer1}>
            <h1 style={{ textAlign: "center", marginBottom: 50 }}>
                We Offer Storage Service
              </h1>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "red" }}
              >
                <p style={{ paddingLeft: 6 }}>Visitor Register</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "blue" }}
              >
                <p style={{ paddingLeft: 6 }}>Registration Form Service</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "green" }}
              >
                <p style={{ paddingLeft: 6 }}>Form Management</p>
              </div>
              <div
                className={styles.detailPoint}
                style={{ borderLeftColor: "darkcyan" }}
              >
                <p style={{ paddingLeft: 6 }}>Custom Field</p>
              </div>
            </div>
            <div className={styles.rightContainer1}>
              <img
                src={webdev}
                alt="web development"
                style={{ height: 550, width: 700, marginRight: 10,}}
              />
            </div>
          </div>
        </>
      )}

      <hr style={{ backgroundColor: "blueviolet", height: 2, marginTop: 20 }} />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
