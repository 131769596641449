import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Deliverypolicy = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <React.Fragment>
      <Header />
      <h2 style={{ textAlign: "center", marginTop: 80 }}>Delivery Policy</h2>
      <Grid container px={5} py={5}>
        <Grid item xs={12} sm={12} md={12}>
          <h4>1. Introduction</h4>
          <p>
            At ByteHub, we are committed to delivering our digital goods and
            services in a timely and efficient manner. This Delivery Policy
            outlines our procedures and guidelines for delivering these services
            to our customers.
          </p>
          <h4>2. Digital Goods Delivery</h4>
          <p>
            Instant Delivery: Most of our digital goods (such as software,
            digital reports, etc.) are delivered instantly via email or through
            a download link on our website. Delivery Time Frame: In cases where
            instant delivery is not possible, we will provide an estimated
            delivery time frame on the product page and during the checkout
            process.
          </p>
          <h4>3. Services Delivery</h4>
          <p>
            Web Development, Online Consultation, and Technical Support: The
            delivery timeline for these services will vary depending on the
            scope and complexity of the project. An estimated delivery timeline
            will be provided after the initial consultation and analysis of the
            project requirements. Project Milestones: For larger projects, we
            may set up milestones, and updates will be provided at each
            completed stage.
          </p>
          <h4>4. Tracking Your Order</h4>
          <p>
            For services where a specific delivery timeline is established,
            customers will be able to track the progress through [method of
            tracking, e.g., a customer portal, emails, etc.].
          </p>
          <h4>5. Delayed Delivery</h4>
          <p>
            In the unlikely event of a delay, we will communicate proactively
            with you to explain the situation and provide a new estimated
            delivery time.
          </p>
          <h4>6. Customer Responsibilities</h4>
          <p>
            To ensure smooth delivery, customers are responsible for providing
            accurate contact details and any necessary information pertinent to
            the delivery of the service.
          </p>
          <h4>7. Changes to Your Order</h4>
          <p>
            If you need to make changes to your order, please contact us as soon
            as possible at{" "}
            <span style={{ color: "blue" }}>contact@bytehubtech.in</span>.
            Changes may affect the delivery timeline.
          </p>
          <h4>8. Contact Us</h4>
          <p>
            If you have any questions about our Delivery Policy or the delivery
            of your order, please contact us at <span style={{ color: "blue" }}>contact@bytehubtech.in</span>.
          </p>
          <h4>9. Policy Updates</h4>
          <p>
            ByteHub reserves the right to modify this Delivery Policy at any
            time, so please review it frequently. Changes and clarifications
            will take effect immediately upon their posting on the website.
          </p>
        </Grid>
      </Grid>
      <hr style={{ backgroundColor: "blueviolet", height: 2 }} />
      <Footer />
    </React.Fragment>
  );
};

export default Deliverypolicy;
