import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Refund = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title="Refund Policy | ByteHub"
  }, []);
  return (
    <React.Fragment>
      <Header />
      <h2 style={{ textAlign: "center", marginTop: 90 }}>Refund Policy</h2>
      <Grid container px={5} py={5}>
        <Grid item xs={12} sm={12} md={12}>
          <h4>1. General Policy</h4>
          <p>
            Thank you for shopping at ByteHub. We appreciate the fact that you
            like to buy the IT services we provide. We also want to make sure
            you have a rewarding experience while you’re exploring, evaluating,
            and purchasing our services. As with any shopping experience, there
            are terms and conditions that apply to transactions at ByteHub.
            We’ll be as brief as our attorneys will allow. The main thing to
            remember is that by placing an order or making a purchase at
            ByteHub, you agree to the terms set forth below along with ByteHub's
            Privacy Policy.
          </p>
          <h4>2. Refundable Services</h4>
          <p>
            Digital Goods: Due to the nature of digital goods, we do not
            typically offer a refund or credit on a purchase unless required
            under India consumer law or other relevant consumer protection laws.
            If you would like to request a refund or credit on a purchase, you
            should open a support ticket at{" "}
            <span style={{ color: "blue" }}>info@bytehubtech.in</span>. Web
            Development and Custom Projects: For web development and other
            custom projects, refunds are handled on a case-by-case basis. If you
            are dissatisfied with the progress or delivery of the project,
            please contact us immediately, and we will work with you to resolve
            the issue to your satisfaction. Online Consultation: For online
            consultation services, refunds are generally not provided unless the
            consultation did not occur due to an error or unavailability on our
            part. Technical Support and Other Services: Refunds for technical
            support and other services will be considered only if the service
            was not rendered as described and a complaint is lodged within 3-4
            days of the service being rendered.
          </p>
          <h4>3. Process for Requesting a Refund</h4>
          <p>
            To request a refund, you must make your request within 3-4 days of
            purchase. Please provide: Your order number. A detailed explanation
            of the issue or reason for the request. Any documentation or
            evidence that supports your request.
          </p>
          <h4>4. Refund Processing</h4>
          <p>
            Refunds will be processed within 7-14 business days. Refunds will be
            issued to the original method of payment. We reserve the right to
            offer a partial refund or store credit in situations where some
            services have been rendered or costs have been incurred on our part.
          </p>
          <h4>5. Contact Information</h4>
          <p>
            For assistance with refunds, please contact our customer support
            team at <span style={{ color: "blue" }}>info@bytehubtech.in</span>.
          </p>
          <h4>6. Changes to the Refund Policy</h4>
          <p>
            ByteHub reserves the right to modify this refund policy at any time,
            so please review it frequently. Changes and clarifications will take
            effect immediately upon their posting on the website.
          </p>
        </Grid>
      </Grid>
      <hr style={{ backgroundColor: "blueviolet", height: 2 }} />
      <Footer />
    </React.Fragment>
  );
};

export default Refund;
