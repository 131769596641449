import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer"

const Privacy = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
    document.title="Privacy Policy | ByteHub"
  },[])
  return (
    <React.Fragment>
      <Header />
      <h2 style={{ textAlign: "center", marginTop: 90 }}>
        Privacy Policy For Bytehub
      </h2>
      <Grid container px={5} py={5}>
        <Grid item xs={12} sm={12} md={12}>
          <p>last update: 10 January 2024</p>
          <h4>1. Introduction</h4>
          <p>
            Welcome to ByteHub ("we", "us", "our"). We are committed to
            protecting the privacy and security of our customers and users. This
            Privacy Policy explains how we collect, use, disclose, and safeguard
            your information when you visit our website and use our services,
            including but not limited to digital goods, web development, online
            consultation, and technical support services.
          </p>
          <h4>2. Information We Collect</h4>
          <p>
            We may collect personal information about you in a variety of ways.
            The information we may collect via our website and services
            includes: Personal Data: Personally identifiable information, such
            as your name, shipping address, email address, and telephone number,
            and demographic information, such as your age, gender, and
            interests, that you voluntarily give to us when you register with
            the website or when you choose to participate in various activities
            related to the website and our services. Derivative Data:
            Information our servers automatically collect when you access the
            website, such as your IP address, browser type, operating system,
            access times, and the pages you have viewed directly before and
            after accessing the website. Financial Data: Financial information,
            such as data related to your payment method (e.g., valid credit card
            number, card brand, expiration date) that we may collect when you
            purchase, order, return, exchange, or request information about our
            services from the website. Data from Social Networks: User
            information from social networking sites, such as [Facebook,
            Instagram, Twitter, etc.], including your name, your social network
            username, location, gender, birth date, email address, profile
            picture, and public data for contacts, if you connect your account
            to such social networks.
          </p>
          <h4>3. Use of Your Information</h4>
          <p>
            We use the information collected about you via the website to:
            Provide our products and services. Process payments and refunds.
            Enhance or personalize your user experience. Communicate with you
            about your account or our services. Compile anonymous statistical
            data for use internally or with third parties. Deliver targeted
            advertising, coupons, newsletters, and other information regarding
            our website and services.
          </p>
          <h4>4. Disclosure of Your Information</h4>
          <p>
            We may share information we have collected about you in certain
            situations. Your information may be disclosed as follows: By Law or
            to Protect Rights: If we believe the release of information about
            you is necessary to respond to legal process, to investigate or
            remedy potential violations of our policies, or to protect the
            rights, property, and safety of others, we may share your
            information as permitted or required by any applicable law, rule, or
            regulation. Third-Party Service Providers: We may share your
            information with third parties that perform services for us or on
            our behalf, including payment processing, data analysis, email
            delivery, hosting services, customer service, and marketing
            assistance.
          </p>
          <h4>5. Security of Your Information</h4>
          <p>
            We use administrative, technical, and physical security measures to
            help protect your personal information. While we have taken
            reasonable steps to secure the personal information you provide to
            us, please be aware that despite our efforts, no security measures
            are perfect or impenetrable, and no method of data transmission can
            be guaranteed against any interception or other type of misuse.
          </p>
          <h4>6. Policy for Children</h4>
          <p>
            We do not knowingly solicit information from or market to children
            under the age of 13. If we learn that any information we collect has
            been provided by a child under the age of 13, we will promptly
            delete that information.
          </p>
          <h4>7. Changes to This Privacy Policy</h4>
          <p>
            We reserve the right to make changes to this Privacy Policy at any
            time and for any reason. We will alert you about any changes by
            updating the "Last Updated" date of this Privacy Policy. You are
            encouraged to periodically review this Privacy Policy to stay
            informed of updates.
          </p>
          <h4>8. Contact Us</h4>
          <p>
            If you have questions or comments about this Privacy Policy, please
            contact us at: <span style={{color:'blue'}}>info@bytehubtech.in</span>.
          </p>
        </Grid>
      </Grid>
      <hr style={{height:2,backgroundColor:'blueviolet'}}/>
      <Footer />
    </React.Fragment>
  );
};

export default Privacy;
