import React, { useState } from "react";
import { Drawer, IconButton, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import styles from "../css/Drawer.module.css";
import CloseIcon from "@mui/icons-material/Close";

const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const handleHome = () => {
    navigate("/");
    window.scrollTo(0, 0);
    setOpenDrawer(!openDrawer);
  };
  const handleService = () => {
    navigate("/services");
    window.scrollTo(0, 0);
    setOpenDrawer(!openDrawer);
  };
  const handleAbout = () => {
    navigate("/about-us");
    window.scrollTo(0, 0);
    setOpenDrawer(!openDrawer);
  };
  const handleContact = () => {
    navigate("/contact-us");
    window.scrollTo(0, 0);
    setOpenDrawer(!openDrawer);
  };
  const handleLogin = () => {
    navigate("/login");
    window.scrollTo(0, 0);
    setOpenDrawer(!openDrawer);
  };

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{ sx: { height: "50%", width: "100%" } }}
      >
        <Stack direction="row-reverse">
          <CloseIcon
            fontSize="large"
            style={{ marginRight: 5 }}
            onClick={() => setOpenDrawer(!openDrawer)}
          />
        </Stack>
        <Stack
          direction="column"
          spacing={2}
          sx={{ marginRight: "auto", marginLeft: "auto" }}
          alignItems="center"
        >
          <button
            className={styles.btn}
            onClick={handleHome}
            style={{ cursor: "pointer", fontWeight: "bold" }}
          >
            Home
          </button>
          <button
            className={styles.btn}
            onClick={handleAbout}
            style={{ cursor: "pointer", fontWeight: "bold" }}
          >
            About Us
          </button>
          <button
            className={styles.btn}
            onClick={handleService}
            style={{ cursor: "pointer", fontWeight: "bold" }}
          >
            Services
          </button>
          {/* <button className={styles.btn}>
                  <Link
                    to="/pricing"
                    style={{
                      color: "black",
                      textDecoration: "None",
                      fontWeight: "bold",
                    }}
                  >
                    Pricing
                  </Link>
                </button> */}
          <button
            className={styles.btn}
            onClick={handleContact}
            style={{ cursor: "pointer", fontWeight: "bold" }}
          >
            Contact Us
          </button>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          marginTop={5}
        >
          <button
            className={styles.outlineButtonMobile}
            onClick={() => navigate("/about-us")}
          >
            Learn More
          </button>
          <button
            className={styles.containButtonMobile}
            onClick={() => navigate("/login")}
          >
            Sign In
          </button>
        </Stack>
        
      </Drawer>
      <IconButton
        sx={{ color: "black", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="white" />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
