import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import styles from "../css/Payment.module.css";
import {
  useTheme,
  useMediaQuery,
  Paper,
  Stack,
  Checkbox,
  Switch,
} from "@mui/material";

const Payment = () => {
  const location = useLocation();
  let serviceName = location.state.name.toUpperCase();
  let sale_Price = location.state.sp;
  let offer_Price = location.state.op;
  let offer_ = location.state.off;
  const [showSwitch, setShowSwitch] = useState(true);
  // const [showOff, setShowOff] = useState(true)
  const [salePrice, setSaleprice] = React.useState(sale_Price);
  const [offerPrice, setOfferprice] = React.useState(offer_Price);
  const [offer, setOffer] = React.useState(offer_);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [showyear, setShowYear] = React.useState(true);
  const handleBillMode = () => {
    setShowYear(!showyear);
    if (showyear === true) {
      if (location.state.op === 399) {
        setSaleprice(4788);
        setOfferprice(4199);
        setOffer(12);
      }
      else if (location.state.op === 499) {
        setSaleprice(5388);
        setOfferprice(4999);
        setOffer(7);
      }
      else if (location.state.op === 599){
        setSaleprice(7188);
        setOfferprice(6470);
        setOffer(10);
      }
      else{
        setSaleprice(8388);
        setOfferprice(7550);
        setOffer(10);
      }
    } else {
      setSaleprice(sale_Price);
      setOfferprice(offer_Price);
      setOffer(offer_);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Checkout page | ByteHub";
    if (serviceName === "VISITOR REGISTER" && serviceName === "REGISTRATION FORM SERVICE") {
      setShowSwitch(false);
    }
  }, []);
  return (
    <React.Fragment>
      <Header />
      {isMatch ? (
        <>
          <div className={styles.checkoutContainerMobile}>
            <div>
              <Paper elevation={0} style={{ width: 400 }}>
                <h3 style={{ textAlign: "center" }}>Order Summary</h3>
                <hr
                  style={{
                    height: 1,
                    backgroundColor: "black",
                    boxShadow: "none",
                    marginBottom: 0,
                    marginTop: 0,
                  }}
                />
                <Stack direction="row" alignItems="center">
                  <h4 style={{ color: "blue", marginRight: 50 }}>
                    {serviceName}
                  </h4>
                  {showSwitch ? (
                    <>
                      <Switch onChange={handleBillMode} />
                      <p>yearly</p>
                    </>
                  ) : null}
                </Stack>
                <hr
                  style={{
                    backgroundColor: "grey",
                    boxShadow: "none",
                    marginTop: 0,
                  }}
                />
                <Stack direction="row" spacing={13}>
                  <Stack direction="column">
                    <p style={{ marginTop: 0, marginBottom: 0 }}>
                      Sale Price:{" "}
                    </p>
                    <p style={{ marginTop: 3, marginBottom: 3 }}>
                      Discount Offer:
                    </p>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      Net Price:{" "}
                    </p>
                  </Stack>
                  <Stack direction="column">
                    <p style={{ marginTop: 0, marginBottom: 0 }}>
                      &#8377;{salePrice}
                    </p>
                    <p
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        marginTop: 3,
                        marginBottom: 3,
                      }}
                    >
                      {offer}%
                    </p>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      &#8377;{offerPrice}
                    </p>
                  </Stack>
                </Stack>
                <hr
                  style={{
                    backgroundColor: "grey",
                    boxShadow: "none",
                  }}
                />
                <h4 style={{ textAlign: "center" }}>
                  Total Save:{" "}
                  <span style={{ color: "red" }}>
                    {" "}
                    &nbsp;&nbsp;&#8377;{salePrice - offerPrice}
                  </span>
                </h4>
              </Paper>
            </div>
            <div>
              <Paper elevation={0}>
                <h2>Personal Details *</h2>
                <Stack spacing={3} alignItems="center">
                  <input
                    type="text"
                    placeholder="Full Name"
                    className={styles.inputBody}
                  />
                  <input
                    type="email"
                    placeholder="E-mail"
                    className={styles.inputBody}
                  />
                  <input
                    type="text"
                    placeholder="Mobile Number"
                    className={styles.inputBody}
                  />
                  <Stack direction="row" alignItems="center">
                    <Checkbox />
                    <p>I agree terms & conditions</p>
                  </Stack>
                  <button className={styles.checkoutButton}>
                    Proceed to Checkout &#8377;{offerPrice}
                  </button>
                </Stack>
              </Paper>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.checkoutContainer}>
            <div>
              <Paper elevation={0}>
                <h3>Personal Details *</h3>
                <Stack spacing={3} alignItems="center">
                  <input
                    type="text"
                    placeholder="Full Name"
                    className={styles.inputBody}
                  />
                  <input
                    type="email"
                    placeholder="E-mail"
                    className={styles.inputBody}
                  />
                  <input
                    type="text"
                    placeholder="Mobile Number"
                    className={styles.inputBody}
                  />
                  <Stack direction="row" alignItems="center">
                    <Checkbox />
                    <p>I agree terms & conditions</p>
                  </Stack>
                  <button className={styles.checkoutButton}>
                    Proceed to Checkout &#8377;{offerPrice}
                  </button>
                </Stack>
              </Paper>
            </div>
            <div>
              <Paper elevation={0} style={{ width: 300 }}>
                <h3 style={{ textAlign: "center" }}>Order Summary</h3>
                <hr
                  style={{
                    height: 1,
                    backgroundColor: "black",
                    boxShadow: "none",
                    marginBottom: 0,
                    marginTop: 0,
                  }}
                />
                <Stack direction="row" alignItems="center">
                  <h4 style={{ color: "blue", marginRight: 50 }}>
                    {serviceName}
                  </h4>
                  {showSwitch ? (
                    <>
                      <Switch onChange={handleBillMode} />
                      <p>yearly</p>
                    </>
                  ) : null}
                </Stack>
                <hr
                  style={{
                    backgroundColor: "grey",
                    boxShadow: "none",
                    marginTop: 0,
                  }}
                />
                <Stack direction="row" spacing={13}>
                  <Stack direction="column">
                    <p style={{ marginTop: 0, marginBottom: 0 }}>
                      Sale Price:{" "}
                    </p>
                    <p style={{ marginTop: 3, marginBottom: 3 }}>
                      Discount Offer:
                    </p>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      Net Price:{" "}
                    </p>
                  </Stack>
                  <Stack direction="column">
                    <p style={{ marginTop: 0, marginBottom: 0 }}>
                      &#8377;{salePrice}
                    </p>
                    <p
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        marginTop: 3,
                        marginBottom: 3,
                      }}
                    >
                      {offer}%
                    </p>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      &#8377;{offerPrice}
                    </p>
                  </Stack>
                </Stack>
                <hr
                  style={{
                    backgroundColor: "grey",
                    boxShadow: "none",
                  }}
                />
                <h4 style={{ textAlign: "center" }}>
                  Total Save:{" "}
                  <span style={{ color: "red" }}>
                    {" "}
                    &nbsp;&nbsp;&#8377;{salePrice - offerPrice}
                  </span>
                </h4>
              </Paper>
            </div>
          </div>
        </>
      )}
      <hr style={{ backgroundColor: "blueviolet", height: 2 }} />
      <Footer />
    </React.Fragment>
  );
};

export default Payment;
