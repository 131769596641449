import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title="Terms and Conditions | ByteHub"
  }, []);
  return (
    <React.Fragment>
      <Header />
      <h2 style={{ textAlign: "center", marginTop: 80 }}>Terms & Conditions</h2>
      <Grid container px={5} py={5}>
        <Grid item xs={12} sm={12} md={12}>
          <h4>1. Introduction</h4>
          <p>
            Welcome to ByteHub! These terms and conditions outline the rules and
            regulations for the use of ByteHub's Website and Services, located
            at <span style={{ color: "blue" }}>bytehubtech.in</span>. By
            accessing this website or using our services, we assume you accept
            these terms and conditions. Do not continue to use ByteHub if you do
            not agree to all of the terms and conditions stated on this page.
          </p>
          <h4>2. Intellectual Property Rights</h4>
          <p>
            Other than the content you own, under these terms, ByteHub and/or
            its licensors own all the intellectual property rights and materials
            contained in this website and our services. You are granted limited
            license only for purposes of viewing the material contained on this
            website and using our services.
          </p>
          <h4>3. Restrictions</h4>
          <p>
            You are specifically restricted from: Publishing any website
            material in any other media without our consent. Selling,
            sublicensing, and/or otherwise commercializing any website material.
            Publicly performing and/or showing any website material. Using this
            website or our services in any way that is or may be damaging to
            ByteHub. Using this website or our services in any way that impacts
            user access to this website. Using this website contrary to
            applicable laws and regulations, or in any way may cause harm to the
            website, or to any person or business entity. Engaging in any data
            mining, data harvesting, data extracting, or any other similar
            activity in relation to this website, or while using this website.
          </p>
          <h4>4. Your Content</h4>
          <p>
            In these terms and conditions, "Your Content" shall mean any audio,
            video, text, images, or other material you choose to display on this
            website or provide to us in the course of using our services. By
            displaying Your Content, you grant ByteHub a non-exclusive,
            worldwide, irrevocable, royalty-free, sublicensable license to use,
            reproduce, adapt, publish, translate, and distribute it in any and
            all media. Your Content must be your own and must not be invading
            any third-party’s rights. ByteHub reserves the right to remove any
            of Your Content from this website at any time without notice
          </p>
          <h4>5. No Warranties</h4>
          <p>
            This website and our services are provided "as is," with all faults,
            and ByteHub expresses no representations or warranties, of any kind
            related to this website or the materials contained on this website.
            Also, nothing contained on this website shall be interpreted as
            advising you.
          </p>
          <h4>6. Limitation of Liability</h4>
          <p>
            In no event shall ByteHub, nor any of its officers, directors, and
            employees, be held liable for anything arising out of or in any way
            connected with your use of this website or our services, whether
            such liability is under contract, tort, or otherwise.
          </p>
          <h4>7. Indemnification</h4>
          <p>
            You hereby indemnify to the fullest extent ByteHub from and against
            any and/or all liabilities, costs, demands, causes of action,
            damages, and expenses arising in any way related to your breach of
            any of the provisions of these terms.
          </p>
          <h4>8. Severability</h4>
          <p>
            If any provision of these terms is found to be invalid under any
            applicable law, such provisions shall be deleted without affecting
            the remaining provisions herein.
          </p>
          <h4>9. Variation of Terms</h4>
          <p>
            ByteHub is permitted to revise these terms at any time as it sees
            fit, and by using this website or our services, you are expected to
            review these terms on a regular basis.
          </p>
          <h4>10. Assignment</h4>
          <p>
            ByteHub is allowed to assign, transfer, and subcontract its rights
            and/or obligations under these terms without any notification.
            However, you are not allowed to assign, transfer, or subcontract any
            of your rights and/or obligations under these terms.
          </p>
          <h4>11. Entire Agreement</h4>
          <p>
            These terms constitute the entire agreement between ByteHub and you
            in relation to your use of this website and our services and
            supersede all prior agreements and understandings.
          </p>
          <h4>12. Governing Law & Jurisdiction</h4>
          <p>
            These terms will be governed by and interpreted in accordance with
            the laws of the Country of India, and you submit to the
            non-exclusive jurisdiction of the state and federal courts located
            in India for the resolution of any disputes.
          </p>
          <h4>13. Contact Information</h4>
          <p>
            For any inquiries or complaints regarding these terms and
            conditions, please contact us at{" "}
            <span style={{ color: "blue" }}>contact@bytehubtech.in</span>.
          </p>
        </Grid>
      </Grid>
      <hr style={{ backgroundColor: "blueviolet", height: 2 }} />
      <Footer />
    </React.Fragment>
  );
};

export default Terms;
