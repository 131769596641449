import { Paper, useTheme, useMediaQuery, Stack } from "@mui/material";
import React, { useEffect } from "react";
import Header from "../components/Header";
import styles from "../css/Products.module.css";
import { useNavigate } from "react-router-dom";
import store from "../images/service-image/store-min.png";
import productManagement from "../images/service-image/product management-min.jpg";
import appointment from "../images/service-image/appointment-min.jpg";
import invoice from "../images/service-image/invoice-min.jpg";
// import onlineOrdering from "../images/service-image/online_ordering-min.png";
// import payment from "../images/service-image//checkout-min.jpg";
import digitalmarketing from "../images/service-image/digital marketing-min.jpg";
// import loyalityPrograms from "../images/service-image/loyality proograms-min.jpg";
import customerBook from "../images/service-image/customer book-min.jpg";
// import landingPage from "../images/landing.png";
import saleFunnels from "../images/service-image/funnels-min.jpg";
import bulkMessaing from "../images/service-image/message-min.jpg";
import registerForm from "../images/service-image/registration-min.jpg";
import visitor from "../images/service-image/visitor.jpg";
import Footer from "../components/Footer";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CheckIcon from "@mui/icons-material/Check";

const Products = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "All Services | ByteHub";
  }, []);

  return (
    <React.Fragment>
      <Header />
      <div className={styles.headerContainer}>
        <Paper elevation={0} style={{ width: "max-content" }}>
          <h3
            style={{
              fontSize: 30,
              fontWeight: "bold",
              textAlign: "center",
              color: "royalblue",
            }}
          >
            Empower Your Business With Us.
          </h3>
          <p
            style={{
              textAlign: "center",
              color: "#2F1C6A",
              fontSize: 20,
              fontWeight: "bold",
              marginBottom: 0,
            }}
          >
            Explore a diverse range of solutions tailored to meet your unique
            needs.
          </p>
          <p
            style={{
              textAlign: "center",
              color: "#2F1C6A",
              fontSize: 20,
              fontWeight: "bold",
              marginTop: 0,
            }}
          >
            From cutting-edge technology to personalized expertise, we are
            committed to delivering unparalleled service that elevates your
            experience.
          </p>
        </Paper>
      </div>
      <Paper
        elevation={0}
        style={{ backgroundColor: "royalblue" }}
        square={true}
      >
        <h1
          style={{
            textAlign: "center",
            color: "white",
            paddingBottom: 5,
          }}
        >
          Byte For E-Commerce
        </h1>
      </Paper>
      <div
        className={isMatch ? styles.cardContainerMobile : styles.cardContainer}
      >
        <Stack
          direction={isMatch ? "column" : "row"}
          spacing={isMatch ? 4 : 2}
          px={10}
        >
          <Paper elevation={0}>
            <h2 style={{ color: "#2F1C6A" }}>Create Your Online Store</h2>

            <p>
              Ready to{" "}
              <span style={{ color: "#2F1C6A", fontWeight: "bold" }}>
                Create, Connect, Convert !!{" "}
              </span>
              we transform your ideas into a thriving online store! Our expert
              team is dedicated to crafting a platform that not only captivates
              your audience but also seamlessly connects and converts visitors
              into loyal customers.
            </p>
            <Stack direction="column">
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Product Listing
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Order Management
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Mobile Responsive
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Report & Analytics
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Discounts and Coupons
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Instore-Pickup
              </Stack>
            </Stack>

            <Stack direction="row" alignItems="center">
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  textDecoration: "line-through",
                  fontSize: 25,
                  color: "#727586",
                }}
              >
                &#8377;
                <p>999</p>
              </Stack>
              <span className={styles.saveOffer}>SAVE 50%</span>
              <CurrencyRupeeIcon fontSize="small" />
              <h1 style={{ color: "#2f1c6a", paddingBottom: 5 }}>499</h1>
              <p style={{ color: "#2f1c6a" }}>/mo</p>
            </Stack>

            <button
              className={styles.bookBtn}
              onClick={() =>
                navigate("/checkout", {
                  state: { name: "Online Store", sp: 999, op: 499, off: 50 },
                })
              }
              style={{ marginTop: 40 }}
            >
              Create Now
            </button>
          </Paper>
          <Paper elevation={0}>
            <img
              src={store}
              alt="online store"
              style={{ height: 400, width: 400, marginTop: 15 }}
            />
          </Paper>
        </Stack>
      </div>
      <Paper
        elevation={0}
        style={{ backgroundColor: "royalblue" }}
        square={true}
      >
        <h1
          style={{
            textAlign: "center",
            color: "white",
            paddingBottom: 5,
          }}
        >
          Byte For Business Process
        </h1>
      </Paper>
      <div
        className={isMatch ? styles.cardContainerMobile : styles.cardContainer}
      >
        <Stack
          direction={isMatch ? "column" : "row"}
          spacing={isMatch ? 4 : 10}
        >
          <Paper className={styles.cardBody} elevation={5}>
            <img
              src={appointment}
              alt="online store"
              style={{ height: 150, width: 150, marginTop: 10 }}
            />
            <h3 style={{ color: "royalblue", marginTop: 0, margin: 12 }}>
              Appointment Scheduling
            </h3>
            <Stack direction="column">
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Appointment Scheduling
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Calendar Integration
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Customer Management
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Mobile Responsive
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Report & Analytics
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                style={{ marginBottom: 0, marginTop: 15 }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{
                    textDecoration: "line-through",
                    fontSize: 25,
                    color: "#727586",
                  }}
                >
                  &#8377;
                  <p style={{ marginBottom: 0, marginTop: 0 }}>999</p>
                </Stack>
                <span className={styles.saveOffer}>SAVE 60%</span>
              </Stack>
              <Stack direction="row" alignItems="center">
                <CurrencyRupeeIcon fontSize="large" color="#2f1c6a" />
                <h1 className={styles.priceTag}>399</h1>
                <p style={{ color: "#2f1c6a" }}>/mo</p>
              </Stack>
            </Stack>
            <button
              className={styles.bookBtn}
              onClick={() =>
                navigate("/checkout", {
                  state: {
                    name: "Appointment Scheduling",
                    sp: 999,
                    op: 399,
                    off: 60,
                  },
                })
              }
            >
              Get Now
            </button>
          </Paper>
          <Paper className={styles.cardBody} elevation={5}>
            <img
              src={productManagement}
              alt="product management"
              style={{ height: 150, width: 150, marginTop: 10 }}
            />
            <h3 style={{ color: "royalblue", marginTop: 0, margin: 12 }}>
              Inventory Management
            </h3>
            <Stack direction="column">
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Product Tracking
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Stock Alerts
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Mobile Responsive
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Order Management
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Report & Analytics
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ marginBottom: 0, marginTop: 15 }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{
                    textDecoration: "line-through",
                    fontSize: 25,
                    color: "#727586",
                  }}
                >
                  &#8377;
                  <p style={{ marginBottom: 0, marginTop: 0 }}>999</p>
                </Stack>
                <span className={styles.saveOffer}>SAVE 60%</span>
              </Stack>
              <Stack direction="row" alignItems="center">
                <CurrencyRupeeIcon fontSize="large" color="#2f1c6a" />
                <h1 className={styles.priceTag}>399</h1>
                <p style={{ color: "#2f1c6a" }}>/mo</p>
              </Stack>
            </Stack>

            <button
              className={styles.bookBtn}
              onClick={() =>
                navigate("/checkout", {
                  state: {
                    name: "Inventory Management",
                    sp: 999,
                    op: 399,
                    off: 60,
                  },
                })
              }
            >
              Get Now
            </button>
          </Paper>
          <Paper className={styles.cardBody} elevation={5}>
            <img
              src={invoice}
              alt="product management"
              style={{ height: 150, width: 150, marginTop: 10 }}
            />
            <h3 style={{ color: "royalblue", marginTop: 0, margin: 12 }}>
              Invoices
            </h3>
            <Stack direction="column">
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Sale Entry
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Purchase Order
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Purchase Entry
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Mobile Responsive
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Report & Analytics
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ marginBottom: 0, marginTop: 15 }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{
                    textDecoration: "line-through",
                    fontSize: 25,
                    color: "#727586",
                  }}
                >
                  &#8377;
                  <p style={{ marginBottom: 0, marginTop: 0 }}>999</p>
                </Stack>
                <span className={styles.saveOffer}>SAVE 60%</span>
              </Stack>
              <Stack direction="row" alignItems="center">
                <CurrencyRupeeIcon fontSize="large" color="#2f1c6a" />
                <h1 className={styles.priceTag}>399</h1>
                <p style={{ color: "#2f1c6a" }}>/mo</p>
              </Stack>
            </Stack>
            <button
              className={styles.bookBtn}
              onClick={() =>
                navigate("/checkout", {
                  state: { name: "Invoices", sp: 999, op: 399, off: 60 },
                })
              }
            >
              Get Now
            </button>
          </Paper>
          <Paper className={styles.cardBody} elevation={5}>
            <img
              src={customerBook}
              alt="online store"
              style={{ height: 150, width: 150, marginTop: 10 }}
            />
            <h3 style={{ color: "royalblue", marginTop: 0, margin: 12 }}>
              Customer Book
            </h3>
            <Stack direction="column">
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Contact Management
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Calender Management
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                E-Mail Integration
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Mobile Responsive
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Report & Analytics
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ marginBottom: 0, marginTop: 15 }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{
                    textDecoration: "line-through",
                    fontSize: 25,
                    color: "#727586",
                  }}
                >
                  &#8377;
                  <p style={{ marginBottom: 0, marginTop: 0 }}>999</p>
                </Stack>
                <span className={styles.saveOffer}>SAVE 50%</span>
              </Stack>
              <Stack direction="row" alignItems="center">
                <CurrencyRupeeIcon fontSize="large" color="#2f1c6a" />
                <h1 className={styles.priceTag}>499</h1>
                <p style={{ color: "#2f1c6a" }}>/mo</p>
              </Stack>
            </Stack>
            <button
              className={styles.bookBtn}
              onClick={() =>
                navigate("/checkout", {
                  state: { name: "Customer Book", sp: 999, op: 499, off: 50 },
                })
              }
            >
              Get Now
            </button>
          </Paper>
        </Stack>
      </div>
      <h1
        style={{
          textAlign: "center",
          marginTop: 40,
          marginBottom: 20,
          color: "#2F1C6A",
        }}
      >
        Dual Mode
      </h1>
      <div
        className={isMatch ? styles.cardContainerMobile : styles.cardContainer}
      >
        <Stack
          direction={isMatch ? "column" : "row"}
          spacing={isMatch ? 4 : 10}
          alignItems='center'
        >
          <Paper elevation={5} className={styles.cardBody}>
            <Stack direction="row" alignItems="center">
              <Stack direction="column" alignItems="center" px={3} py={3}>
                <h3 style={{ color: "royalblue",marginBottom: 0, marginTop: 5  }}>
                  Inventory Management + Invoices
                </h3>

                <Stack
                  direction="row"
                  alignItems="center"
                  style={{ marginBottom: 0, marginTop: 5 }}
                >
                  <h3>399 + 399 = &nbsp;</h3>
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{
                      textDecoration: "line-through",
                      fontSize: 25,
                      color: "#727586",
                    }}
                  >
                    &#8377;
                    <p style={{ marginBottom: 0, marginTop: 0 }}>798</p>
                  </Stack>
                  <span className={styles.saveOffer}>SAVE 25%</span>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <CurrencyRupeeIcon fontSize="large" color="#2f1c6a" />
                  <h1 className={styles.priceTag}>599</h1>
                  <p style={{ color: "#2f1c6a" }}>/mo</p>
                </Stack>
              </Stack>
            </Stack>
            <button
              className={styles.bookBtn}
              onClick={() =>
                navigate("/checkout", {
                  state: { name: "Inventory Management + Invoices", sp: 798, op: 599, off: 25 },
                })
              }
            >
              Get Now
            </button>
          </Paper>
          <Paper elevation={5} className={styles.cardBody}>
            <Stack direction="row" alignItems="center" px={3} py={3}>
              <Stack direction="column" alignItems="center">
                <h3 style={{ color: "royalblue",marginBottom: 0, marginTop: 5  }}>
                Appointment Scheduling + Customer Book
                </h3>

                <Stack
                  direction="row"
                  alignItems="center"
                  style={{ marginBottom: 0, marginTop: 5 }}
                >
                  <h3>399 + 499 = &nbsp;</h3>
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{
                      textDecoration: "line-through",
                      fontSize: 25,
                      color: "#727586",
                    }}
                  >
                    &#8377;
                    <p style={{ marginBottom: 0, marginTop: 0 }}>898</p>
                  </Stack>
                  <span className={styles.saveOffer}>SAVE 22%</span>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <CurrencyRupeeIcon fontSize="large" color="#2f1c6a" />
                  <h1 className={styles.priceTag}>699</h1>
                  <p style={{ color: "#2f1c6a" }}>/mo</p>
                </Stack>
              </Stack>
            </Stack>
            <button
              className={styles.bookBtn}
              onClick={() =>
                navigate("/checkout", {
                  state: { name: "Appointment Scheduling + Customer Book", sp: 898, op: 699, off: 22 },
                })
              }
            >
              Get Now
            </button>
          </Paper>
        </Stack>
      </div>
      <Paper
        elevation={0}
        style={{ backgroundColor: "royalblue" }}
        square={true}
      >
        <h1
          style={{
            textAlign: "center",
            color: "white",
            paddingBottom: 5,
          }}
        >
          Byte For Digital Marketing
        </h1>
      </Paper>
      <h1
        style={{
          textAlign: "center",
          marginTop: 40,
          marginBottom: 20,
          color: "#2F1C6A",
        }}
      >
        Create, Connect and Communicate By Digitally
      </h1>
      <div
        className={isMatch ? styles.cardContainerMobile : styles.cardContainer}
      >
        <Stack
          direction={isMatch ? "column" : "row"}
          spacing={isMatch ? 4 : 10}
        >
          <Paper className={styles.cardBody} elevation={5}>
            <img
              src={digitalmarketing}
              alt="online store"
              style={{ height: 150, width: 200, marginTop: 10 }}
            />
            <h3 style={{ color: "royalblue", marginTop: 0, margin: 12 }}>
              Digital Marketing
            </h3>
            <Stack direction="column">
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Google Ads
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Facebook Ads
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                E-Mail Marketing
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Mobile Responsive
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Report & Analytics
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ marginBottom: 0, marginTop: 15 }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{
                    textDecoration: "line-through",
                    fontSize: 25,
                    color: "#727586",
                  }}
                >
                  &#8377;
                  <p style={{ marginBottom: 0, marginTop: 0 }}>999</p>
                </Stack>
                <span className={styles.saveOffer}>SAVE 50%</span>
              </Stack>
              <Stack direction="row" alignItems="center">
                <CurrencyRupeeIcon fontSize="large" color="#2f1c6a" />
                <h1 className={styles.priceTag}>499</h1>
                <p style={{ color: "#2f1c6a" }}>/mo</p>
              </Stack>
            </Stack>
            <button
              className={styles.bookBtn}
              onClick={() =>
                navigate("/checkout", {
                  state: {
                    name: "Digital Marketing",
                    sp: 999,
                    op: 499,
                    off: 50,
                  },
                })
              }
            >
              Get Now
            </button>
          </Paper>
          <Paper className={styles.cardBody} elevation={5}>
            <img
              src={saleFunnels}
              alt="product management"
              style={{ height: 150, width: 200, marginTop: 10 }}
            />
            <h3 style={{ color: "royalblue", marginTop: 0, marginBottom: 12 }}>
              Sales Funnels
            </h3>
            <Stack direction="column">
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Content Creation
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Loyality Programs
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Landing Pages
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Mobile Responsive
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Report & Analytics
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ marginBottom: 0, marginTop: 15 }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{
                    textDecoration: "line-through",
                    fontSize: 25,
                    color: "#727586",
                  }}
                >
                  &#8377;
                  <p style={{ marginBottom: 0, marginTop: 0 }}>999</p>
                </Stack>
                <span className={styles.saveOffer}>SAVE 50%</span>
              </Stack>
              <Stack direction="row" alignItems="center">
                <CurrencyRupeeIcon fontSize="large" color="#2f1c6a" />
                <h1 className={styles.priceTag}>499</h1>
                <p style={{ color: "#2f1c6a" }}>/mo</p>
              </Stack>
            </Stack>
            <button
              className={styles.bookBtn}
              onClick={() =>
                navigate("/checkout", {
                  state: { name: "Sales Funnels", sp: 999, op: 499, off: 50 },
                })
              }
            >
              Get Now
            </button>
          </Paper>
          <Paper className={styles.cardBody} elevation={5}>
            <img
              src={bulkMessaing}
              alt="product management"
              style={{ height: 150, width: 200, marginTop: 10 }}
            />
            <h3 style={{ color: "royalblue", marginTop: 0, marginBottom: 12 }}>
              Bulk Messaging
            </h3>
            <Stack direction="column">
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Custom Message
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Direct To Customer
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Contact Management
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Mobile Responsive
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Report & Analytics
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ marginBottom: 0, marginTop: 15 }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{
                    textDecoration: "line-through",
                    fontSize: 25,
                    color: "#727586",
                  }}
                >
                  &#8377;
                  <p style={{ marginBottom: 0, marginTop: 0 }}>999</p>
                </Stack>
                <span className={styles.saveOffer}>SAVE 50%</span>
              </Stack>
              <Stack direction="row" alignItems="center">
                <CurrencyRupeeIcon fontSize="large" color="#2f1c6a" />
                <h1 className={styles.priceTag}>499</h1>
                <p style={{ color: "#2f1c6a" }}>/mo</p>
              </Stack>
            </Stack>
            <button
              className={styles.bookBtn}
              onClick={() =>
                navigate("/checkout", {
                  state: { name: "Bulk Messaging", sp: 999, op: 499, off: 50 },
                })
              }
            >
              Get Now
            </button>
          </Paper>
        </Stack>
      </div>

      <Paper
        elevation={0}
        style={{ backgroundColor: "royalblue" }}
        square={true}
      >
        <h1
          style={{
            textAlign: "center",
            color: "white",
            paddingBottom: 5,
          }}
        >
          Data Storage
        </h1>
      </Paper>
      <div
        className={isMatch ? styles.cardContainerMobile : styles.cardContainer}
      >
        <Stack
          direction={isMatch ? "column" : "row"}
          spacing={isMatch ? 4 : 10}
        >
          <Paper className={styles.cardBody} elevation={5}>
            <img
              src={visitor}
              alt="online store"
              style={{ height: 150, width: 150, marginTop: 10 }}
            />
            <h3 style={{ color: "royalblue", marginTop: 0, marginBottom: 12 }}>
              Visitor Register
            </h3>
            <Stack direction="column">
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Paperless Management
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                QR Code Scanner
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Contact Management
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Mobile Responsive
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Report & Analytics
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ marginBottom: 0, marginTop: 15 }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{
                    textDecoration: "line-through",
                    fontSize: 25,
                    color: "#727586",
                  }}
                >
                  &#8377;
                  <p style={{ marginBottom: 0, marginTop: 0 }}>499</p>
                </Stack>
                <span className={styles.saveOffer}>SAVE 60%</span>
              </Stack>
              <Stack direction="row" alignItems="center">
                <CurrencyRupeeIcon fontSize="large" color="#2f1c6a" />
                <h1 className={styles.priceTag}>199</h1>
                <p style={{ color: "#2f1c6a" }}>/mo</p>
              </Stack>
            </Stack>
            <button
              className={styles.bookBtn}
              onClick={() =>
                navigate("/checkout", {
                  state: {
                    name: "Visitor Register",
                    sp: 499,
                    op: 199,
                    off: 60,
                  },
                })
              }
            >
              Get Now
            </button>
          </Paper>
          <Paper className={styles.cardBody} elevation={5}>
            <img
              src={registerForm}
              alt="product management"
              style={{ height: 150, width: 150, marginTop: 10 }}
            />
            <h3 style={{ color: "royalblue", marginTop: 0, marginBottom: 0 }}>
              Registration Form
            </h3>
            <h3 style={{ color: "royalblue", marginTop: 0, marginBottom: 5 }}>
              Service
            </h3>
            <Stack direction="column">
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                All Types Of Forms
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Form Management
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                QR-Code Scanner
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Mobile Responsive
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ color: "grey" }}
              >
                <CheckIcon
                  sx={{ color: "green", marginRight: 1 }}
                  fontSize="small"
                />
                Report & Analytics
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                style={{ marginBottom: 0, marginTop: 15 }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{
                    textDecoration: "line-through",
                    fontSize: 25,
                    color: "#727586",
                  }}
                >
                  &#8377;
                  <p style={{ marginBottom: 0, marginTop: 0 }}>499</p>
                </Stack>
                <span className={styles.saveOffer}>SAVE 60%</span>
              </Stack>
              <Stack direction="row" alignItems="center">
                <CurrencyRupeeIcon fontSize="large" color="#2f1c6a" />
                <h1 className={styles.priceTag}>199</h1>
                <p style={{ color: "#2f1c6a" }}>/mo</p>
              </Stack>
            </Stack>
            <button
              className={styles.bookBtn}
              onClick={() =>
                navigate("/checkout", {
                  state: {
                    name: "Registration Form Service",
                    sp: 499,
                    op: 199,
                    off: 60,
                  },
                })
              }
            >
              Get Now
            </button>
          </Paper>
        </Stack>
      </div>
      <hr style={{ height: 2, backgroundColor: "blueviolet", marginTop: 40 }} />
      <Footer />
    </React.Fragment>
  );
};

export default Products;
