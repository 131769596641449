import React from "react";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Pricing from "./pages/Pricing";
import Products from "./pages/Products";
import Signup from "./pages/Signup";
import Payment from "./pages/Payment";
import About from"./pages/About";
import  Terms from "./pages/Terms";
import  Privacy from "./pages/Privacy";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Refund from "./pages/Refund";
import Deliverypolicy from "./pages/Deliverypolicy";



function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about-us" element={<About />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="login" element={<Login />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="services" element={<Products />} />
          <Route path="signup" element={<Signup />} />
          <Route path="checkout" element={<Payment />} />
          <Route path="privacy-policy" element={<Privacy />} />
          <Route path="refund-policy" element={<Refund />} />
          <Route path="terms-conditions" element={<Terms />} />
          <Route path="delivery-policy" element={<Deliverypolicy />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
