import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import Header from "../components/Header";
import backimg from "../images/background.jpg";
import Footer from "../components/Footer";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title= "About Us | ByteHub"
  }, []);
  return (
    <React.Fragment>
      <Header />
      <h2 style={{ textAlign: "center", marginTop: 80 }}>About Us</h2>
      <Grid container px={5} py={5}>
        <Grid item xs={12} sm={6} md={6}>
          <h3>Welcome to Bytehub – Your Digital Partner</h3>
          <p>
            At Bytehub, we are dedicated to crafting exceptional digital
            experiences and providing top-notch services to meet your every
            technological need. Our team, comprising a group of seasoned IT
            experts, is passionate about innovation, customer satisfaction, and
            excellence in every project we undertake.
          </p>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <img
            src={backimg}
            alt="about page pic"
            style={{ height: 300, width: 400, marginLeft: 50 }}
          />
        </Grid>
      </Grid>
      <h2 style={{ textAlign: "center" }}>Our Mission</h2>
      <Grid container px={5} py={5}>
        <Grid item xs={12} sm={12} md={12}>
          <h3>Empowering Your Digital Journey</h3>
          <p>
            Our mission is to empower businesses and individuals in navigating
            the digital landscape seamlessly. We strive to deliver cutting-edge
            solutions, whether it's developing robust web and mobile
            applications, offering technical support, or providing online
            consultations.
          </p>
          <h2 style={{ textAlign: "center" }}>What Sets Us Apart</h2>
          <h3>Expertise that Transcends</h3>
          <p>
            With a team of skilled professionals, Bytehub brings a wealth of
            experience to the table. Our experts specialize in digital products,
            services, technical support, and web and app development. We don't
            just meet expectations; we exceed them.
          </p>
          <h3>Customer-Centric Approach</h3>
          <p>
            Your success is our priority. We believe in building lasting
            relationships with our clients, understanding their unique needs,
            and delivering tailor-made solutions. Our commitment to customer
            satisfaction sets us apart in the digital landscape.
          </p>
          <h2 style={{ textAlign: "center" }}>Services We Offer</h2>
          <h3>Digital Products:</h3>
          <p>
            Explore a range of innovative digital products designed to enhance
            your online presence and user experience.
          </p>
          <h3>Digital Services:</h3>
          <p>
            From digital marketing to cybersecurity, our comprehensive digital
            services cater to every aspect of your online strategy.
          </p>
          <h3>Technical Support:</h3>
          <p>
            Our dedicated support team is here to ensure that your systems run
            smoothly, providing timely assistance whenever you need it.
          </p>
          <h3>Online Consultation: </h3>
          <p>
            Get expert advice and insights through our online consultation
            services, helping you make informed decisions in the digital realm.
          </p>
          <h3>Web and App Development: </h3>
          <p>
            Transform your ideas into reality with our proficient web and app
            development services. We create solutions that resonate with your
            audience.
          </p>
          <h4>
            <u>Join Us in the Digital Revolution</u>
          </h4>
          <p>
            Whether you're a business looking to elevate your online presence or
            an individual seeking expert guidance, Bytehub is here for you. Join
            us in the digital revolution as we pave the way for innovation,
            excellence, and success.
          </p>
          <h4>Bytehub – Where Ideas Transform into Digital Reality.</h4>
          <h3 style={{ textAlign: "center", color: "blue" }}>
            Feel free to bring your idea to us...!!
          </h3>
        </Grid>
      </Grid>
      <hr style={{ backgroundColor: "blueviolet", height: 2 }} />
      <Footer />
    </React.Fragment>
  );
};

export default About;
