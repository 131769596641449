import {
  Grid,
  Stack,
  Paper,
  Button,
  Dialog,
  DialogContent,
  Divider,
  TextField,
  useTheme,
  useMediaQuery
} from "@mui/material";
import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from "../css/Contact.module.css";
import SendIcon from "@mui/icons-material/Send";
import Lottie from "lottie-react";
import emailSendSuccess from "../lotties/successemailsend.json";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const Contact = () => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title="Contact Us | ByteHub"
  }, []);
  return (
    <React.Fragment>
      <Header />
      <Grid
        container
        className={isMatch?styles.mainContainerMobile:styles.mainContainer}
      >
        <Paper className={styles.contactBody} elevation={4}>
          <h3 style={{ marginRight: "auto" }}>Message Us:</h3>
          <Stack direction="column">
            <Stack direction="row" alignItems="center">
              <Paper className={styles.inputContainer} elevation={0}>
                <Stack direction="row" alignItems="center">
                  <PersonIcon style={{ marginLeft: 10, marginRight: 10 }} />
                  <Divider
                    orientation="vertical"
                    variant="fullwidth"
                    flexItem={true}
                  />
                  <input className={styles.inputBody} placeholder="Full Name" />
                </Stack>
              </Paper>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Paper className={styles.inputContainer} elevation={0}>
                <Stack direction="row" alignItems="center">
                  <EmailIcon style={{ marginLeft: 10, marginRight: 10 }} />
                  <Divider
                    orientation="vertical"
                    variant="fullwidth"
                    flexItem={true}
                  />
                  <input className={styles.inputBody} placeholder="E-mail" />
                </Stack>
              </Paper>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Paper className={styles.inputContainer} elevation={0}>
                <Stack direction="row" alignItems="center">
                  <CallIcon style={{ marginLeft: 10, marginRight: 10 }} />
                  <Divider
                    orientation="vertical"
                    variant="fullwidth"
                    flexItem={true}
                  />
                  <input
                    className={styles.inputBody}
                    placeholder="Mobile Number"
                  />
                </Stack>
              </Paper>
            </Stack>
            <TextField
              multiline
              rows={3}
              placeholder="What's on your mind write here"
              sx={{ marginTop: 3 }}
            />
            <Button
              variant="contained"
              sx={{
                marginTop: 5,
                marginBottom: 5,
                backgroundColor: "blueviolet",
              }}
              onClick={() => setOpen(true)}
            >
              Send
              <SendIcon style={{ paddingLeft: 5 }} fontSize="small" />
            </Button>
          </Stack>
        </Paper>
        <Paper className={styles.contactinfoBody} style={{backgroundColor:'#333333'}} elevation={4}>
          <h3 style={{ marginRight: "auto", color: "white",}}>
            Contact Us:
          </h3>
          <Stack direction="row" alignItems="center">
            <CallIcon fontSize="large" style={{ color: "white",marginRight:5 }} />
            <p style={{ fontSize: 18, color: "yellow", fontWeight: "bold" }}>
              Phone: +91 6370461442, +91 98611682247
            </p>
          </Stack>
          <Stack direction="row" alignItems="center">
            <EmailIcon fontSize="large" style={{ color: "white",marginRight:5  }} />
            <p style={{ fontSize: 18, color: "yellow", fontWeight: "bold" }}>
              E-mail: contact@bytehubtech.in
            </p>
          </Stack>
          <Stack direction="row">
            <LocationOnIcon fontSize="large" style={{ color: "white",marginRight:5  }} />
            <Stack>
              <p
                style={{
                  marginBottom: 0,
                  color: "yellow",
                  fontWeight: "bold",
                }}
              >
                liv 16, firstfloor
              </p>
              <p
                style={{
                  marginBottom: 0,
                  marginTop: 0,
                  color: "yellow",
                  fontWeight: "bold",
                }}
              >
                Rudrapur Sasan, Bachhipur
              </p>
              <p
                style={{
                  marginBottom: 0,
                  marginTop: 0,
                  color: "yellow",
                  fontWeight: "bold",
                }}
              >
                Near Bachhipur U.P. School
              </p>
              <p
                style={{
                  marginBottom: 0,
                  marginTop: 0,
                  color: "yellow",
                  fontWeight: "bold",
                }}
              >
                Bhadrak, Odisha
              </p>
              <p
                style={{
                  marginBottom: 0,
                  marginTop: 0,
                  color: "yellow",
                  fontWeight: "bold",
                }}
              >
                756165
              </p>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      <Dialog open={open}>
        <DialogContent>
          <Lottie
            animationData={emailSendSuccess}
            loop={1}
            onComplete={() => setOpen(false)}
            style={{
              height: 300,
              width: 300,
            }}
          />
        </DialogContent>
      </Dialog>
      <hr
        style={{ backgroundColor: "blueviolet", height: 2, marginTop: 400 }}
      />
      <Footer />
    </React.Fragment>
  );
};

export default Contact;
