import {
  Paper,
  Stack,
  Grid,
  Fab,
  Dialog,
  DialogContent,
  DialogContentText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useRef } from "react";
import styles from "../css/Footer.module.css";
import SendIcon from "@mui/icons-material/Send";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Lottie from "lottie-react";
import thanksForSuscribe from "../lotties/thanks suscribe.json";
import ErrorLottie from "../lotties/error.json";

const Footer = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const emailInput = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [emailInputError, setEmailInputError] = React.useState(false);
  const handleSubmit = () => {
    const email = emailInput.current.value;
    if (email) {
      setOpen(true);
    } else {
      setEmailInputError(true);
    }
    console.log(email);
  };
  return (
    <React.Fragment>
      <div className={styles.mainContainer}>
        <Grid container py={8} px={5}>
          <Grid item xs={12} sm={6} md={4}>
            <h3 style={{ color: "blueviolet", marginRight: "auto" }}>
              Suscribe Now
            </h3>
            <p>Don't miss our future updates! Get Subscribed Today!</p>
            <Paper
              className={
                isMatch
                  ? styles.suscribeInputContainerMobile
                  : styles.suscribeInputContainer
              }
              sx={{ borderRadius: 20 }}
            >
              <Stack direction="row" alignItems="center">
                <input
                  className={
                    isMatch ? styles.inputBodyMobile : styles.inputBody
                  }
                  ref={emailInput}
                />
                {isMatch ? undefined : (
                  <SendIcon
                    fontSize="large"
                    style={{ color: "blueviolet", cursor: "pointer" }}
                    className={styles.iconBtn}
                    onClick={handleSubmit}
                  />
                )}
              </Stack>
            </Paper>
            {isMatch ? (
              <>
                <Stack alignItems="center">
                  <button className={styles.sendBtn} onClick={handleSubmit}>Suscribe</button>
                </Stack>
              </>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Stack direction="column" alignItems="center">
              <p>
                <Link
                  to="/contact-us"
                  style={{
                    color: "black",
                    textDecoration: "None",
                    marginRight: "auto",
                  }}
                >
                  Contact Us
                </Link>
              </p>
              <p>
                <Link
                  to="/privacy-policy"
                  style={{
                    color: "black",
                    textDecoration: "None",
                  }}
                >
                  Privacy Policy
                </Link>
              </p>
              <p>
                <Link
                  to="/delivery-policy"
                  style={{
                    color: "black",
                    textDecoration: "None",
                  }}
                >
                  Delivery Policy
                </Link>
              </p>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Stack direction="column" alignItems="center">
              <p>
                <Link
                  to="/about-us"
                  style={{
                    color: "black",
                    textDecoration: "None",
                    marginRight: "auto",
                  }}
                >
                  About Us
                </Link>
              </p>
              <p>
                <Link
                  to="/refund-policy"
                  style={{
                    color: "black",
                    textDecoration: "None",
                  }}
                >
                  Refund Policy
                </Link>
              </p>
              <p>
                <Link
                  to="/terms-conditions"
                  style={{
                    color: "black",
                    textDecoration: "None",
                  }}
                >
                  Terms & Conditions
                </Link>
              </p>
              {isMatch ? undefined : (
                <>
                  <Fab color="secondary" style={{ paddingTop: 10 }}>
                    <KeyboardArrowUpIcon
                      sx={{ paddingBottom: 1 }}
                      onClick={() => window.scrollTo(0, 0)}
                    />
                  </Fab>
                </>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Stack direction="column">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <h2 style={{ color: "blue" }}>Follow Us</h2>
                {isMatch ? (
                  <>
                    <Fab color="secondary" sx={{}}>
                      <KeyboardArrowUpIcon
                        onClick={() => window.scrollTo(0, 0)}
                      />
                    </Fab>
                  </>
                ) : undefined}
              </Stack>

              <Stack direction="row" alignItems="center">
                <FacebookIcon
                  fontSize="large"
                  style={{
                    cursor: "pointer",
                    color: "#316FF6",
                  }}
                />
                <LinkedInIcon
                  fontSize="large"
                  style={{
                    cursor: "pointer",
                    color: "#0077b5",
                    marginLeft: 5,
                    marginRight: 5,
                  }}
                />
                <TwitterIcon
                  fontSize="large"
                  style={{
                    cursor: "pointer",
                    color: "#1DA1F2",
                    marginLeft: 5,
                    marginRight: 5,
                  }}
                />
                <YouTubeIcon
                  fontSize="large"
                  style={{
                    cursor: "pointer",
                    color: "#CD201F",
                    marginLeft: 5,
                    marginRight: 5,
                  }}
                />
                <InstagramIcon
                  fontSize="large"
                  style={{
                    cursor: "pointer",
                    color: "#CD201F",
                  }}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </div>
      <Dialog open={open}>
        <DialogContent>
          <Lottie
            animationData={thanksForSuscribe}
            loop={0}
            onComplete={() => setOpen(false)}
            style={{
              height: 300,
              width: 300,
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={emailInputError}>
        <DialogContent>
          <Lottie
            animationData={ErrorLottie}
            loop={1}
            onComplete={() => setEmailInputError(false)}
            style={{
              height: 200,
              width: 200,
            }}
          />
          <DialogContentText
            sx={{ textAlign: "center", color: "black", fontWeight: "bold" }}
          >
            Please Enter a Valid E-mail
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default Footer;
