import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  useMediaQuery,
  useTheme,
  Stack,
  Paper,
  Divider,
} from "@mui/material";
import DrawerComp from "./Drawer";
import { useNavigate } from "react-router-dom";
import styles from "../css/Header.module.css";
import logo1 from "../images/logo1.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

const Header = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };
  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };
  const handleHome = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };
  const handleService = () => {
    navigate("/services");
  };
  const handleAbout = () => {
    navigate("/about-us");
    window.scrollTo(0, 0);
  };
  const handleContact = () => {
    navigate("/contact-us");
    window.scrollTo(0, 0);
  };
  const handleLogin = () => {
    navigate("/login");
    window.scrollTo(0, 0);
  };

  return (
    <React.Fragment>
      <AppBar sx={{ background: "white", boxShadow: 5 }}>
        <Toolbar
          // onMouseEnter={handleMouseLeave}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={logo1}
            style={{
              height: 80,
              width: 250,
              marginRight: 10,
              cursor: "pointer",
            }}
            alt="logo1"
            onClick={handleHome}
          />
          {isMatch ? (
            <>
              <DrawerComp />
            </>
          ) : (
            <>
              <Stack
                direction="row"
                spacing={2}
                sx={{ marginRight: "auto", marginLeft: "auto" }}
              >
                <button
                  className={styles.btn}
                  onClick={handleHome}
                  style={{ cursor: "pointer", fontWeight: "bold" }}
                  onMouseEnter={handleMouseLeave}
                >
                  Home
                </button>
                <button
                  className={styles.btn}
                  onClick={handleAbout}
                  style={{ cursor: "pointer", fontWeight: "bold" }}
                  onMouseEnter={handleMouseLeave}
                >
                  About Us
                </button>
                <button
                  className={styles.btn}
                  onClick={handleService}
                  style={{ cursor: "pointer", fontWeight: "bold" }}
                  onMouseEnter={handleMouseEnter}
                >
                  <Stack direction="row" alignItems="center">
                    Services
                    <ExpandMoreIcon />
                  </Stack>
                </button>

                <button
                  className={styles.btn}
                  onClick={handleContact}
                  style={{ cursor: "pointer", fontWeight: "bold" }}
                  onMouseEnter={handleMouseLeave}
                >
                  Contact Us
                </button>
              </Stack>
              <Stack direction="row" sx={{ marginLeft: "auto" }}>
                <button
                  className={styles.loginbtn}
                  onClick={handleLogin}
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    color: "#2E77AE",
                  }}
                >
                  Login
                </button>
                <button
                  className={styles.signupbtn}
                  onClick={handleLogin}
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Let's Started
                </button>
              </Stack>
            </>
          )}
        </Toolbar>
      </AppBar>
      {isDropdownVisible && (
        <>
          <Paper
            className={styles.dropdownContent}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            elevation={8}
          >
            <Stack
              direction="row"
              spacing={6}
              paddingLeft={5}
              paddingTop={3}
              paddingRight={5}
            >
              <Stack direction="column" alignItems="flex-start">
                <h3 style={{ color: "#2F1C6A" }}>Byte For E-Commerce</h3>
                <p className={styles.popbtn}>Online Store</p>
              </Stack>
              <Stack direction="column" alignItems="flex-start">
                <h3 style={{ color: "#2F1C6A" }}>Byte For Business Process</h3>
                <p className={styles.popbtn}>Appointment</p>
                <p className={styles.popbtn}>Invoice</p>
                <p className={styles.popbtn}>Inventory Management</p>
                <p className={styles.popbtn}>Customer Book</p>
              </Stack>
              <Stack direction="column" alignItems="flex-start">
                <h3 style={{ color: "#2F1C6A" }}>Byte For Digital Marketing</h3>
                <p className={styles.popbtn}>Digital Marketing</p>
                <p className={styles.popbtn}>Sales Funnels</p>
                <p className={styles.popbtn}>Bulk Messaging</p>
              </Stack>
              <Stack direction="column" alignItems="center">
                <h3 style={{ color: "#2F1C6A" }}>Byte For Data Storage</h3>
                <p className={styles.popbtn}>Visitor Register</p>
                <p className={styles.popbtn}>Registration</p>
              </Stack>
            </Stack>
          </Paper>
        </>
      )}
    </React.Fragment>
  );
};

export default Header;
