import {
  Grid,
  useTheme,
  useMediaQuery,
  Stack,
  Divider,
  Paper,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from "../css/Login.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import googlelogo from "../images/google logo.png";
import Lottie from "lottie-react";
import loginLottie from "../lotties/login lottie.json";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";

const Login = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [showSignin, setShowignin] = React.useState(false);
  const [showSignPass, setShowSignPass] = React.useState(false);
  const [showSigncPass, setShowSigncPass] = React.useState(false);
  const [showLoginPass, setShowLoginPass] = React.useState(false);
  
  const navigate = useNavigate();
  const name = useRef(null);
  const login_email = useRef(null);
  const signin_email = useRef(null);
  const login_password = useRef(null);
  const signin_password = useRef(null);
  const cpassword = useRef(null);
  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        console.log(response);
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v1/userinfo",
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title="Login | ByteHub"
  }, []);
  return (
    <React.Fragment>
      <Header />
      <Grid container py={5} px={5} style={{ marginTop: 90 }}>
        {isMatch ? undefined : (
          <>
            <Grid item md={6} sm={6}>
              <Lottie
                animationData={loginLottie}
                style={{ height: 500, width: 500 }}
              />
            </Grid>
          </>
        )}
        {showSignin ? (
          <>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              lg={6}
              
              className={isMatch? styles.loginContainerMobile :styles.loginContainer}
            >
              <h2 style={{ color: "white", textAlign: "center" }} >Sign In</h2>
              <Stack direction="column" alignItems="center" >
                <Stack direction="row" alignItems="center">
                  <Paper className={styles.loginInputContainer} elevation={0}>
                    <Stack direction="row" alignItems="center">
                      <PersonIcon style={{ marginLeft: 10, marginRight: 10 }} />
                      <Divider
                        orientation="vertical"
                        variant="fullwidth"
                        flexItem={true}
                      />
                      <input
                        className={styles.inputBody}
                        placeholder="Full Name"
                        ref={name}
                      />
                    </Stack>
                  </Paper>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Paper className={styles.loginInputContainer} elevation={0}>
                    <Stack direction="row" alignItems="center">
                      <EmailIcon style={{ marginLeft: 10, marginRight: 10 }} />
                      <Divider
                        orientation="vertical"
                        variant="fullwidth"
                        flexItem={true}
                      />
                      <input
                        className={styles.inputBody}
                        placeholder="E-mail"
                        ref={signin_email}
                      />
                    </Stack>
                  </Paper>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Paper className={styles.loginInputContainer} elevation={0}>
                    <Stack direction="row" alignItems="center">
                      <LockIcon style={{ marginLeft: 10, marginRight: 10 }} />
                      <Divider
                        orientation="vertical"
                        variant="fullwidth"
                        flexItem={true}
                      />
                      <input
                        className={styles.inputBody}
                        placeholder="Password"
                        ref={signin_password}
                        type={showSignPass ? "text" : "password"}
                      />
                      <VisibilityIcon
                        color={showSignPass ? "primary" : "black"}
                        onClick={() => setShowSignPass(!showSignPass)}
                        sx={{ cursor: "pointer" }}
                        
                      />
                    </Stack>
                  </Paper>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Paper className={styles.loginInputContainer} elevation={0}>
                    <Stack direction="row" alignItems="center">
                      <LockIcon style={{ marginLeft: 10, marginRight: 10 }} />
                      <Divider
                        orientation="vertical"
                        variant="fullwidth"
                        flexItem={true}
                      />
                      <input
                        className={styles.inputBody}
                        placeholder="Confirm Password"
                        ref={cpassword}
                        type={showSigncPass ? "text" : "password"}
                      />
                      <VisibilityIcon
                        color={showSigncPass ? "primary" : "black"}
                        onClick={() => setShowSigncPass(!showSigncPass)}
                        sx={{ cursor: "pointer" }}
                      />
                    </Stack>
                  </Paper>
                </Stack>
              </Stack>
              <Stack alignItems="center" paddingTop={5}>
                <button className={styles.btn}>Sign In</button>
                <p style={{ color: "white", paddingLeft: 30 }}>
                  Already Have an Account ?
                  <span
                    // style={{fontSize:18}}
                    className={styles.signupSpan}
                    onClick={() => setShowignin(false)}
                  >
                    Login
                  </span>
                </p>
              </Stack>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              lg={6}
              className={isMatch?styles.loginContainerMobile: styles.loginContainer}
            >
              <h2 style={{ color: "white", textAlign: "center" }}>Login</h2>
              <Stack direction="column" alignItems="center">
                <Stack direction="row" alignItems="center">
                  <Paper className={styles.loginInputContainer} elevation={0}>
                    <Stack direction="row" alignItems="center">
                      <EmailIcon style={{ marginLeft: 10, marginRight: 10 }} />
                      <Divider
                        orientation="vertical"
                        variant="fullwidth"
                        flexItem={true}
                      />
                      <input
                        className={styles.inputBody}
                        placeholder="E-mail"
                        ref={login_email}
                      />
                    </Stack>
                  </Paper>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Paper className={styles.loginInputContainer} elevation={0}>
                    <Stack direction="row" alignItems="center">
                      <LockIcon style={{ marginLeft: 10, marginRight: 10 }} />
                      <Divider
                        orientation="vertical"
                        variant="fullwidth"
                        flexItem={true}
                      />
                      <input
                        className={styles.inputBody}
                        placeholder="Password"
                        ref={login_password}
                        type={showLoginPass ? "text" : "password"}
                      />
                      <VisibilityIcon
                        color={showLoginPass ? "primary" : "black"}
                        onClick={() => setShowLoginPass(!showLoginPass)}
                        sx={{ cursor: "pointer" }}
                      />
                    </Stack>
                  </Paper>
                </Stack>
              </Stack>
              <Stack alignItems="flex-end" paddingRight={15}>
                <p
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  Forgot Password ?
                </p>
              </Stack>
              <Stack alignItems="center">
                <button className={styles.btn} style={{ marginRight: 10 }}>
                  Login
                </button>
              </Stack>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="center"
                style={{ paddingLeft: 30 }}
              >
                <p style={{ color: "white" }}>
                  New to ByteHub ?
                  <span
                    className={styles.signupSpan}
                    onClick={() => setShowignin(true)}
                  >
                    Sign Up
                  </span>
                </p>
              </Stack>
              <Stack alignItems="center" marginBottom={5}>
                <Paper onClick={googleLogin} style={{ cursor: "pointer" }}>
                  <Stack direction="row" alignItems="center">
                    <img src={googlelogo} style={{ height: 50, width: 50 }} />
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        marginRight: 5,
                      }}
                    >
                      Sign In with google
                    </p>
                  </Stack>
                </Paper>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
      <hr style={{ backgroundColor: "blueviolet", height: 2 }} />
      <Footer />
    </React.Fragment>
  );
};

export default Login;
